// Vendor Imports
import React, { useState } from "react";
import { useDispatch, connect, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Local Imports
import CreditRequestModal from './CreditRequest'
import UpgrdeRequestModal from '../../client/UpgradeSubscription/UpgrdeRequestModal'
import { renderCount } from '../../../utils'

import {
  refreshUserDetails,
  setMyTransactions, setMySavedSearches, setCurrentSearch, setSearchResult
} from '../../../reducers/client/actions';
import {
  getMyAccount,
  getMyTransactions, getMySavedSearches, doNewDownload
} from '../../../service/client';

// new search donwload confirmation component
function SearchDownloadConfirm(props) {

  const [loading, setLoading] = useState('');
  const [openDialog, setDialogOpen] = React.useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector((store) => store.client.myaccount);
  const [upgradeModal, setUpgradeModal] = useState(false);

  // toggle upgrade request if primary user did not have enough credit balace
  const toggleUpgrade = () => {
    setUpgradeModal(!upgradeModal) 
  };

  const [creditModal, setCreditModal] = useState(false);
  // toggle credit request if sub user did not have enough credit balace to primary user
  const toggleCreditRequest = () => {
    setCreditModal(!creditModal)
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // function to download current search records  
  const handleDownload = () => {
    setLoading('loading');
    var element = document.getElementById("body");
    element.classList.add("downloadloading");
    let params = {
      lastSearchNumMatched: parseInt(props.searchResult.lastSearchNumMatched),
      lastSearchParams: props.currentSearch.search_params,
      currentTableID: props.searchResult.currentTableID,
      expired: props.currentSearch.expired,
      saveddownload_id: props.currentSearch.id,
      record2Charge: props.searchResult.credit_required,
      download_name: props.currentSearch.name
    }
    
    let newArr=[];
    let roleData=[];
    let populationData=[];
    let populationDataMore=[];
    let ftestrTotal=[];
    let criteriastrTotal=[];
    let exptypestrTotal=[];
    let allState=[];
    let govTypeData=[];
    let elecMonth=[];
    let fiscalMnth=[];
    let expQrtSel=[];
    let fteQrt=[];
    let zipcodeData=[];
    let offEmailData=[];
    let newOfficialData=[];
    let refinFilterData=[];
    let ftequartileData=[];
    let finSpendData=[];

    let roleValue=[];
    let roleHeadofReplace='';
    let roleHead_ofReplace='';
    let populationValue='';
    let populationValueData='';
    let allStateValue=[];
    let elecMonthValue=[];
    let fiscalMnthValue='';
    let expQrtSelValue=[];
    let fteQrtValue=[];
    let govTypeDataValue=[];
    let contact_zipcode='';
    let contact_zipcode_readius='';
    let contact_refine_result_filter_include='No';
    let contact_refine_result_filter_exclude='No';

    let contact_FTE_category='';
    let contact_FTE_subcategory='';
    let contact_spend_category='';
    let contact_spend_subcategory='';
    let contact_newoffical_filter='No';
    let contact_recordwithemail_filter='No';

    {params.lastSearchParams.split("&").map((item, index) => {
      if(item.search('role_'))
      {

        if(item.search('populationMoreThan'))
        {

          if(item.search('populationThan'))
          {

            if(item.search('govLoc_byState'))
            {

              if(item.search('FTEQuartileSel'))
              {

                if(item.search('expenseBudgetQuartileSel'))
                {

                  if(item.search('fiscalYearEnd_Month'))
                  {

                    if(item.search('electionMonth'))
                    {

                      if(item.search('govType_'))
                      {

                        if(item.search('totalFTE'))
                        {

                          if(item.search('expType'))
                          {

                            if(item.search('criteria'))
                            {

                              if(item.search('byZipcode'))
                              {
                                contact_zipcode='NA';
                                contact_zipcode_readius='NA';
                                

                                if(item.search('off_email'))
                                {
                                  if(item.search('purchasedOnly'))
                                  {

                                    if(item.search('OnlyHotLeads'))
                                    {
                                      contact_newoffical_filter='No';
                                      

                                      if(item.search('fte'))
                                      {
                                          contact_FTE_category="";
                                          contact_FTE_subcategory="";
                                          
                                          if(item.search('fin'))
                                          {
                                            contact_spend_category="";;
                                            contact_spend_subcategory=""
                                            
                                          } else {
                                            finSpendData=item.split("=");
                                            if(finSpendData[0]=='finPS')
                                            {
                                              contact_spend_category="Public Safety";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finPW')
                                            {
                                              contact_spend_category="Public Welfare";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finH')
                                            {
                                              contact_spend_category="Health";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finU')
                                            {
                                              contact_spend_category="Utilities";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finT')
                                            {
                                              contact_spend_category="Transportation";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finL')
                                            {
                                              contact_spend_category="Leisure";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finF')
                                            {
                                              contact_spend_category="Finance";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finM')
                                            {
                                              contact_spend_category="Miscellaneous";
                                              contact_spend_subcategory=finSpendData[1];
                                            }
                                          }


                                      } else {
                                          ftequartileData=item.split("=");
                                          if(ftequartileData[0]=='ftePS')
                                          {
                                            contact_FTE_category="Public Safety";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='fteH')
                                          {
                                            contact_FTE_category="Health";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='ftePW')
                                          {
                                            contact_FTE_category="Public Welfare";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='fteU')
                                          {
                                            contact_FTE_category="Utilities";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='fteT')
                                          {
                                            contact_FTE_category="Transport";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='fteL')
                                          {
                                            contact_FTE_category="Leisure";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='fteF')
                                          {
                                            contact_FTE_category="Administration";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='fteM')
                                          {
                                            contact_FTE_category="Miscellanous";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                      }

                                    } else {
                                      newOfficialData=item.split("=");
                                      if(newOfficialData[0]=='OnlyHotLeads')
                                      {
                                        contact_newoffical_filter='Yes';
                                      } else {
                                        contact_newoffical_filter = 'No';
                                      }
                                    }

                                  } else {
                                    refinFilterData=item.split("=");
                                    if(refinFilterData[0]=='purchasedOnly')
                                    {
                                      contact_refine_result_filter_include = 'Yes';
                                    } else {
                                      contact_refine_result_filter_include = 'No';
                                    }

                                    if(refinFilterData[0]=='NotpurchasedOnly')
                                    {
                                      contact_refine_result_filter_exclude = 'Yes';
                                    } else {
                                      contact_refine_result_filter_exclude = 'No';
                                    }

                                  }


                                } else {
                                  offEmailData=item.split("=");
                                  if(offEmailData[1]=='govtOff_email')
                                  {
                                    contact_recordwithemail_filter = 'Yes';
                                  } else {
                                    contact_recordwithemail_filter = 'No';
                                  }

                                }


                              } else {

                                zipcodeData=item.split("=");
                                if(zipcodeData[0]=='byZipcode')
                                {
                                  contact_zipcode=zipcodeData[1];
                                }
                                if(zipcodeData[0]=='byZipCodeDistance')
                                {
                                  contact_zipcode_readius=zipcodeData[1];
                                }
                              }

                            } else {
                              criteriastrTotal=item.split("=");
                              //console.log('Criteria =',criteriastrTotal[1]);
                            }


                          } else {
                            exptypestrTotal=item.split("=");
                            if(exptypestrTotal[0]==2)
                            {
                              contact_spend_category="Special Categories";
                              contact_spend_subcategory= "Only Capital Expenditures";
                            }
                            if(exptypestrTotal[0]==3)
                            {
                              contact_spend_category="Special Categories";
                              contact_spend_subcategory= "Only Operating Expenditures";
                            }
                            if(exptypestrTotal[0]==4)
                            {
                              contact_spend_category="Special Categories";
                              contact_spend_subcategory= "Only Salaries & Wages";
                            }
                            if(exptypestrTotal[0]==5)
                            {
                              contact_spend_category="Special Categories";
                              contact_spend_subcategory= "Only Expenditures on Suppliers";
                            }
                          }

                        } else {
                          ftestrTotal=item.split("=");
                          //console.log('Total FTE =',ftestrTotal[1]);
                        }

                      } else {
                        govTypeData=item.split("=");
                        //govTypeDataValue+='"'+govTypeData[1].charAt(0).toUpperCase()+ govTypeData[1].slice(1)+'",';
                        govTypeDataValue.push(govTypeData[1].charAt(0).toUpperCase()+ govTypeData[1].slice(1));
                      }

                    } else {
                      elecMonth=item.split("=");
                      if(elecMonth[1]==13)
                      {
                        elecMonth[1]='Not Avlb';
                      }
                      elecMonthValue.push(elecMonth[1]);
                      
                    }

                  } else {
                    fiscalMnth=item.split("=");
                    if(fiscalMnth[1]=='ot+Known')
                    {
                        fiscalMnth[1]='Not Avlb';
                    }
                    //fiscalMnthValue.push(fiscalMnth[1]);
                    fiscalMnthValue+=fiscalMnth[1]+',';
                    
                  }

                } else {
                  expQrtSel=item.split("=");
                  if(expQrtSel[1]==5)
                  {
                    expQrtSel[1]='Others';
                  }
                  expQrtSelValue.push(expQrtSel[1]);
                  
                }

              } else {
                fteQrt=item.split("=");
                if(fteQrt[1]==5)
                {
                  fteQrt[1]='Others';
                }
                fteQrtValue.push(fteQrt[1]);
                
              }


            } else {
              allState=item.split("=");
              allStateValue.push(allState[1].replaceAll('+', " "));
              //='"'+allState[1].replaceAll('+', " ")+'",';
              
            }

          } else {
            populationData=item.split("=");
          }

        } else {
          populationDataMore=item.split("=");
        }
      } else {
        roleData=item.split("=");
        roleHeadofReplace = roleData[1].replaceAll('Headof', "");
        roleHead_ofReplace = roleHeadofReplace.replaceAll('+', " ");
        if(roleHead_ofReplace=='Zoning'){
          roleHead_ofReplace='Planning/Zoning';
        } 
        if(roleHead_ofReplace=='Head of Finance/Budgeting'){
          roleHead_ofReplace='Finance';
        }  
        if(roleHead_ofReplace=='Head of Purchasing/Procurement'){
          roleHead_ofReplace='Purchasing';
        }  
        if(roleHead_ofReplace=='Head of Fire Protection Services'){
          roleHead_ofReplace="Fire";
        }  
        if(roleHead_ofReplace=='Head Building Official'){
          roleHead_ofReplace="Buildings";
        }  
        if(roleHead_ofReplace=='Clerk'){
          roleHead_ofReplace="Clerk's Office";
        }
        roleValue.push(roleHead_ofReplace.replaceAll('Head of ', ""));
      }
      
    })}
    /*let fteQuartileArrayVal=fteQrtValue.substring(0, fteQrtValue.length-1).split(',').sort((a, b) => a-b);
    let spendQuartileArrayVal=expQrtSelValue.substring(0, expQrtSelValue.length-1).split(',').sort((a, b) => a-b);
    const order = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const sort = (data) =>data.split(',').sort((a, b) => order.indexOf(a) - order.indexOf(b)).join();
    let electionMonthArrayVal=elecMonthValue.substring(0, elecMonthValue.length-1).split(',').sort((a, b) => a-b);*/
    
    const order = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const sort1 = (data) =>data.split(',').sort((a, b) => order.indexOf(a) - order.indexOf(b)).join();
    const fiscalData=sort1(fiscalMnthValue.substring(0, fiscalMnthValue.length-1));

    //console.log('sdfsafdsadf',params.lastSearchParams);
    setDialogOpen(true);
    doNewDownload(params).then(res => {
      if (res.data.code === 200) {
          if (window.analytics) {
             window.analytics.track('Records Downloaded', {
                search_name:params.download_name,
                search_id:params.saveddownload_id,
                expire:'no',
                contact_role : roleValue,
                contact_population_min : populationDataMore[1],
                contact_population_max : populationData[1],
                contact_state : allStateValue,
                contact_zipcode : contact_zipcode,
                contact_zipcode_radius : contact_zipcode_readius,
                contact_government_type : govTypeDataValue,
                contact_FTE_quartile : fteQrtValue,
                contact_FTE_category : contact_FTE_category,
                contact_FTE_subcategory : contact_FTE_subcategory,
                contact_spend_quartile : expQrtSelValue,
                contact_spend_category : contact_spend_category,
                contact_spend_subcategory : contact_spend_subcategory,
                contact_fiscal_ending : fiscalData.split(','),
                contact_election_month : elecMonthValue.sort((a, b) => a-b),
                contact_recordwithemail_filter : contact_recordwithemail_filter,
                contact_newoffical_filter : contact_newoffical_filter,
                contact_refine_result_filter_include : contact_refine_result_filter_include,
                contact_refine_result_filter_exclude : contact_refine_result_filter_exclude,
              });
          }
      }
      DownloadFile(res);
    });

  }
  // download record file and redirect my saved search page
  const DownloadFile = (response) => {
    setLoading('');
    var element = document.getElementById("body");
    element.classList.remove("downloadloading");
    if (response.data.code === 200) {
      setDialogOpen(false);
      let data = response.data.data;
      let file = data.file;
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = file;
      a.download = data.file_name;
      document.body.appendChild(a);
      a.click();
      setTimeout(()=>{
        dispatch(setCurrentSearch([]));
        dispatch(setSearchResult([]));
        dispatch(setMySavedSearches([]));
        getMyAccount().then(res =>
          dispatch(refreshUserDetails(res.data.data))
        );
        getMyTransactions().then(res =>
          dispatch(setMyTransactions(res.data.data)),
        )  
      },500)
      setTimeout(()=>{
          props.history.push('/my-saved-searches#downloads');
      },500)

    } else {
      setDialogOpen(false);
      alert("Your Download is Not Successful. Please Try Again");
    }
  }

  let total_records = parseInt(props.searchResult.lastSearchNumMatched);
  let paid = parseInt(props.searchResult.paid_records);
  let creditsAvail = parseInt(props.info.creditsRemaining);
  let credit_required = props.searchResult.credit_required;
  let credit_purchase = credit_required - creditsAvail - paid;
  if (credit_purchase < 0) {
    credit_purchase = 0;
  }
  let after_download = 0;
  if (credit_required < creditsAvail) {
    after_download = creditsAvail - credit_required;
  }
  // function to take decision whether user is eligible download with enough credit or need checkout or request to upgrade / credit request
  const RenderButton = () => {

    if (credit_purchase !== 0) {
      if (props.info.isParent === true) {
        if (props.bestPricing.option === "Upgrade" && (props.plan.Sub_Level === "11" ||  props.plan.Sub_Level === "9")) {
          return (
            <Link className="btn btn-pa button download-btn " to="/search/download/upgrade-plan" >
              <strong> {props.bestPricing.pricing_option} </strong>
            </Link>);
        } else if (props.bestPricing.option === "Upgrade" && (props.plan.Sub_Level !== "10" && props.plan.Sub_Level !== "11" && props.plan.Sub_Level !== "9")) {
          return (
            <Link className="btn btn-pa button download-btn " onClick={toggleUpgrade} >
              <strong> {props.bestPricing.pricing_option} </strong>
            </Link>);
        } else if (props.bestPricing.option === "Purchase" && (props.plan.Sub_Level !== "10")) {
          return (
            <Link className="btn btn-pa button download-btn " to="/search/download/purchase-credits" >   <strong> {props.bestPricing.pricing_option} </strong>
            </Link>);
        } else {
          return (<Link className="btn btn-pa button download-btn " onClick={toggleCreditRequest} variant="container" >
            <strong> {props.bestPricing.pricing_option} </strong>
          </Link>);
        }

      } else if (props.info.isParent === false) {
        if (props.bestPricing.option !== "Download") {

          return (
            <>
              <Link className="btn btn-pa button download-btn " onClick={toggleCreditRequest} variant="container" >
                <strong> Request More Credits Now </strong>
              </Link>
              <p>(send an email request to this account's master user)</p>
            </>
          );
        } else {
          return (
            <>

            </>
          );
        }

      }
    } else {
      return (
        <Button onClick={handleDownload} variant="contained" className={"  btn btn-pa button download-btn " + loading}>
          Proceed to Start the Download
          <i className="ml-2 fa fa-download" aria-hidden="true"></i>
        </Button>
      )
    }

  }

  return (
    <>
      <div style={{ marginBottom: "5%" }}>
        <section className="my-search">
          <div className="container">
            <div className="row">
              <div className="col">
                <ul className="breadcrumb">
                  <li>
                    <Link to="/search">New Search</Link>
                  </li>
                  <li>Download Records</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          <div className="col-12">
            <div className="panel panel-primary download-trans pt-5">
              <div className="panel-heading account-test text-center">
                DOWNLOAD CONFIRMATION
              </div>
              <div className="panel-body background-grey add-subscribe ">
                <div className="row ">
                  {
                    props.plan.Sub_Level !== "10" ?

                      <div className="col-sm-12 ">
                        <div className="col-sm-6 float-left text-left download-records">
                          <div className="row">
                            <div className="col-md-10 text-right">
                              <b>Total records in this file:</b>
                            </div>
                            <div className="col-md-2 text-right">
                              <b>{renderCount(total_records)}</b>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 text-right">
                              Total credits required to download the file:
                            </div>
                            <div className="col-md-2 text-right">{renderCount(credit_required)}</div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 text-right">
                              Total credits available PRIOR TO this purchase:
                            </div>
                            <div className="col-md-2 text-right">{renderCount(creditsAvail)}</div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 text-right">
                              Credits you must purchase:
                            </div>
                            <div className="col-md-2 text-right">{renderCount(credit_purchase)}</div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 text-right">
                              Credit balance after you purchase and download:
                            </div>
                            <div className="col-md-2 text-right">{renderCount(after_download)}</div>
                          </div>
                        </div>

                        <div className="col-sm-6 float-left text-left download-records">
                          {
                            credit_purchase === 0 ?
                              <p>
                                <strong>
                                  Good News! You have enough credits for this download.
                                </strong>
                              </p>
                              :
                              props.info.isParent === true ?

                                <p>
                                  <strong> You need to buy more credits to download this file.
                                  </strong>
                                  <br />
                                  <strong>The total cost for these additional credits is: $ {renderCount(props.bestPricing.bestPrice.totalPrice)} </strong>
                                </p>
                                :
                                <p>
                                  <strong> You need to have more credits to download this file.
                                  </strong>

                                </p>
                          }

                          {
                            credit_purchase === 0 ?
                              <p>
                                <Button onClick={handleDownload} variant="contained" className={"  btn btn-pa button download-btn " + loading}>
                                  Proceed to Start the Download

                                  <i className="ml-2 fa fa-download" aria-hidden="true"></i>
                                </Button>
                              </p>
                              :
                              <p>
                                <RenderButton />
                              </p>

                          }

                        </div>
                      </div>
                      :
                      <div className="col-sm-12 text-center">
                        <p>
                          <strong>
                            Total records in this file: {renderCount(props.searchResult.lastSearchNumMatched)}
                          </strong>
                        </p>
                        <p>
                          <Button onClick={handleDownload} variant="contained" className={"  btn btn-pa button download-btn " + loading}>
                            Proceed to Start the Download

                            <i className="ml-2 fa fa-download" aria-hidden="true"></i>
                          </Button>

                        </p>{" "}
                      </div>
                  }

                  <div className="col-12  text-center">
                    <Link to="/search" className="back_btn">
                      <span>
                        <i
                          className="fa fa-angle-double-left"
                          aria-hidden="true"
                        ></i>
                      </span>
                      Back to Search
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Downloading</DialogTitle>
        <DialogContent>
          <DialogContentText className="text-center" id="alert-dialog-description">
            Please be patient, we are putting together your file now, it may take 30 to 45 seconds.
            <p><img src="/images/loading.gif" /> </p>
          </DialogContentText>
        </DialogContent>

      </Dialog>
      <UpgrdeRequestModal modal={upgradeModal} toggle={toggleUpgrade} />
      {
        props.info.isParent === false ?
          <CreditRequestModal modal={creditModal} toggle={toggleCreditRequest} />
          :
          ""
      }
    </>
  );

}

function mapStateToProps(store, props) {
  return {
    bestPricing: store.client.bestPricing ? store.client.bestPricing : [],
    plan: store.client.plan ? store.client.plan : [],
    info: store.client.info ? store.client.info : [],
    searchResult: store.client.searchResult ? store.client.searchResult : [],
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
    checkout: store.client.checkout ? store.client.checkout : [],
  }
}

export default withRouter(connect(mapStateToProps, {})(SearchDownloadConfirm));
